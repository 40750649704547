import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import _ from 'lodash'
import html2pdf from 'html2pdf.js'

import { NewContainer, NewHeader } from '../../components'

import { getQueryDate, history, getUserFullName } from '../../utils'
import { usePrevious } from '../../hooks'

import iconDownload from '../../images/icons/icon_export_dark.svg'
import iconRMS from '../../images/icons/icon_rms.png'
import iconIntrmodl from '../../images/icons/icon_intrmodl.svg'
import iconComments from '../../images/icons/icon_comments_print.svg'
import iconNoComments from '../../images/icons/icon_no_comments_print.png'

import * as S from './styles'

const PrintAudits = ({
  yardId,
  timezone,
  audits,
  createdAtStart,
  createdAtEnd,
  loadAudits,
  workerId,
  workerType,
  resetSelectedUser,
  workerInfo,
  yardList,
}) => {
  const [loading, setLoading] = useState(false)
  const previousYardId = usePrevious(yardId)
  const currentYardInfo = yardList.find((yard) => yard.id === yardId)

  const pdfHeaderTitle = `${_.get(currentYardInfo, 'code')} - ${_.get(
    currentYardInfo,
    'name',
  )}`

  const getFilterOptions = useCallback(() => {
    const { startDate, endDate } = getQueryDate({
      createdAtStart,
      createdAtEnd,
      timezone,
    })

    const filterOptions = {
      yardId,
      startDate: moment.tz(startDate, timezone).startOf('day'),
      endDate: moment.tz(endDate, timezone).endOf('day'),
      offset: 0,
      limit: 100,
    }

    return filterOptions
  }, [createdAtEnd, createdAtStart, timezone, yardId])

  useEffect(() => {
    loadAudits({
      filterOptions: getFilterOptions(),
      workerId,
      workerType,
    })

    return () => resetSelectedUser()
  }, [loadAudits, workerId, workerType, resetSelectedUser, getFilterOptions])

  useEffect(() => {
    if (!_.isNil(previousYardId) && previousYardId !== yardId) {
      history.push(`/yard/${yardId}/audits`)
    }
  }, [previousYardId, yardId])

  function handlePrintAudits() {
    setLoading(true)
    const pdfHeader = document.querySelector('.pdf-header')
    const pdfContent = document.querySelectorAll('.pdf-content')

    const pdfContentHTML = [...pdfContent].map(
      (nodeElement) => nodeElement.outerHTML,
    )

    pdfContentHTML.unshift(pdfHeader.outerHTML)

    const options = {
      margin: 0.1,
      filename: `${pdfHeaderTitle} Audits.pdf`,
      image: { type: 'png', quality: 1 },
      dpi: 600,
      html2canvas: { scale: 1 },
      pageBreak: { mode: ['css'] },
      jsPDF: {
        unit: 'in',
        format: 'a4',
        orientation: 'portrait',
      },
    }

    html2pdf()
      .set(options)
      .from(pdfContentHTML.join(''))
      .toCanvas()
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages()
        _(totalPages).times((index) => {
          pdf.setPage(index)
          pdf.setFontSize(9)
          pdf.setTextColor(100)
          pdf.text(
            pdf.internal.pageSize.getWidth() - 2,
            pdf.internal.pageSize.getHeight() - 0.1,
            'Only up to 100 audits were printed',
          )
          pdf.text(
            pdf.internal.pageSize.getWidth() - 8.2,
            pdf.internal.pageSize.getHeight() - 0.1,
            `Page ${index === 0 ? totalPages : index} of ${totalPages}`,
          )
        })
      })
      .save()

    return setLoading(false)
  }

  const titleAction = [
    {
      key: 0,
      icon: iconDownload,
      onClick: handlePrintAudits,
      tooltip: {
        title: 'Download PDF file',
        action: 'Click to download',
        description:
          'Click this button to download the printable PDF file of the preview',
      },
      disabled:
        audits.loading ||
        (!audits.loading && _.isEmpty(audits.value)) ||
        loading,
    },
  ]

  const headerProps = {
    title: 'Audit PDF preview',
    returnText: 'Back to Audits',
    returnUrl: `yard/${yardId}/audits`,
    titleAction,
  }

  const auditList = _.get(audits, 'value', [])
  const auditsTotalCount = _.get(audits, 'count.total')
  const auditsWithViolations = _.get(audits, 'count.withViolations')
  const auditsWithViolationsRate =
    auditsTotalCount > 0
      ? Math.round((auditsWithViolations / auditsTotalCount) * 100)
      : '-'

  return (
    <>
      <NewHeader {...headerProps} />
      <NewContainer>
        <S.Content>
          <S.Preview id="pdfPreview">
            <div className="pdf-header">
              <S.Header>
                <S.Icon src={iconRMS} />
                <div>
                  <S.Text bold uppercase>{`${pdfHeaderTitle} audits`}</S.Text>
                  <S.Text>{` | ${moment
                    .tz(createdAtStart, timezone)
                    .format('M.DD.YYYY')} - ${moment
                    .tz(createdAtEnd, timezone)
                    .format('M.DD.YYYY')}`}</S.Text>
                </div>
                <S.Icon src={iconIntrmodl} />
              </S.Header>

              <S.Wrapper metrics>
                <div>
                  <S.Text small uppercase>
                    Total Audits
                  </S.Text>
                  <S.Box bigFont highlight>
                    {auditsTotalCount}
                  </S.Box>
                </div>
                <div>
                  <S.Text small uppercase>
                    Audits with violations
                  </S.Text>
                  <S.Box bigFont highlight>
                    {auditsWithViolations}
                  </S.Box>
                </div>
                <div>
                  <S.Text small uppercase>
                    % of audits with violations
                  </S.Text>
                  <S.Box bigFont highlight>
                    {auditsWithViolationsRate}%
                  </S.Box>
                </div>
              </S.Wrapper>
            </div>

            {auditList.map((audit, index) => {
              const hasViolations = !_.isEmpty(audit.violations)
              const hasComments = !_.isEmpty(audit.comment)

              return (
                <S.AuditContainer key={audit.id} className="pdf-content">
                  {!_.isEmpty(workerInfo) && (
                    <div>
                      <S.FlexWrapper>
                        <S.Text small uppercase>
                          Worker
                        </S.Text>
                        {
                          <S.Text small right>
                            {moment
                              .tz(audit.updatedAt, timezone)
                              .format('MM.DD.YYYY HH:mm')}
                          </S.Text>
                        }
                      </S.FlexWrapper>
                      <S.Box bold highlight>
                        <S.Text bold>{getUserFullName(workerInfo)}</S.Text>
                      </S.Box>
                    </div>
                  )}
                  <div>
                    <S.FlexWrapper>
                      <S.Text small uppercase>
                        {hasViolations ? 'Violations' : 'No violations'}
                      </S.Text>
                      {_.isEmpty(workerInfo) && (
                        <S.Text small right>
                          {moment
                            .tz(audit.updatedAt, timezone)
                            .format('MM.DD.YYYY HH:mm')}
                        </S.Text>
                      )}
                    </S.FlexWrapper>

                    {hasViolations &&
                      _.get(audit, 'violations', []).map((violation) => (
                        <S.Box bold highlight key={index}>
                          <S.Text bold>{violation}</S.Text>
                        </S.Box>
                      ))}
                  </div>

                  <S.AuditDetailsWrapper>
                    <div className="short-column">
                      <S.Text small uppercase>
                        Employee
                      </S.Text>
                      <S.Box>{audit.employee}</S.Box>
                    </div>
                    <div className="short-column">
                      <S.Text small uppercase>
                        Auditor
                      </S.Text>
                      <S.Box>{audit.auditor}</S.Box>
                    </div>
                    <div className="short-column">
                      <S.Text small uppercase>
                        Supervisor
                      </S.Text>
                      <S.Box>{audit.supervisor}</S.Box>
                    </div>
                    <div className="full-column">
                      <S.Text small uppercase>
                        Corrective action
                      </S.Text>
                      <S.Box>
                        {_.get(audit, 'correctiveActions', []).join(', ') ||
                          '-'}
                      </S.Box>
                    </div>
                  </S.AuditDetailsWrapper>

                  <S.CommentBox>
                    <S.Text small uppercase>
                      <img
                        src={hasComments ? iconComments : iconNoComments}
                        alt="icon that shows the comment state"
                      />{' '}
                      {hasComments ? 'Comments' : 'No comments'}
                    </S.Text>
                    {hasComments && <S.Box>{audit.comment}</S.Box>}
                  </S.CommentBox>
                </S.AuditContainer>
              )
            })}
          </S.Preview>
        </S.Content>
      </NewContainer>
    </>
  )
}

PrintAudits.propTypes = {
  yardId: PropTypes.number.isRequired,
  createdAtStart: PropTypes.number.isRequired,
  createdAtEnd: PropTypes.number.isRequired,
  workerId: PropTypes.number,
  workerType: PropTypes.string,
  timezone: PropTypes.string.isRequired,
  loadAudits: PropTypes.func.isRequired,
  audits: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    count: PropTypes.shape({
      total: PropTypes.number.isRequired,
      withViolations: PropTypes.number.isRequired,
    }),
    value: PropTypes.arrayOf(
      PropTypes.shape({
        yardCode: PropTypes.string.isRequired,
        auditor: PropTypes.string.isRequired,
        supervisor: PropTypes.string.isRequired,
        employee: PropTypes.string.isRequired,
        violations: PropTypes.arrayOf(PropTypes.string).isRequired,
        correctiveActions: PropTypes.arrayOf(PropTypes.string).isRequired,
        createdAt: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  resetSelectedUser: PropTypes.func.isRequired,
  returnUrl: PropTypes.string,
  workerInfo: PropTypes.object,
  yardList: PropTypes.array.isRequired,
}

PrintAudits.defaultProps = {
  workerId: null,
  workerType: null,
  returnUrl: null,
  workerInfo: {},
}

export default PrintAudits
