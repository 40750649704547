import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'

import { NewContainer, NewHeader, NewTabs, Loading } from '../../components'
// import YardSummary from '../YardSummary'

import { analyticsDashboardNameToId, analyticsInitialMode } from '../../config'
import { history } from '../../utils'
import api from '../../api'

import * as S from './styles'

const submenuOptionsList = {
  vehicles: [
    { text: `Engine Hours`, value: 'engine_hours', key: 0 },
    { text: `VCR's`, value: 'vcrs', key: 1 },
    {
      key: 2,
      text: `VWR's (coming soon)`,
      value: 'vwrs',
      disabled: true,
    },
  ],
  productivity: [
    { text: 'LPMH', value: 'lpmh', key: 0 },
    { text: 'Hours', value: 'hours', key: 1 },
    { text: 'Flags', value: 'flags', key: 2 },
    // { text: `Daily`, value: 'daily', key: 3 },
  ],
}

const viewModeOptions = [
  {
    key: 0,
    text: 'Productivity',
    value: 'productivity',
  },
  {
    key: 1,
    text: 'Vehicles',
    value: 'vehicles',
  },
  {
    key: 2,
    text: 'Safety',
    value: 'safety',
  },
]

export default function Analytics({
  yardName,
  URLViewMode,
  URLSubmenu,
  timezone,
}) {
  const [dashboardUrl, setDashboardUrl] = useState(null)
  const [yardSummarySelectedDate, setYardSummarySelectedDate] = useState(
    moment.tz(timezone).subtract(1, 'day').format('YYYY-MM-DD'),
  )

  const analyticsApiId = analyticsDashboardNameToId[URLSubmenu || URLViewMode]
  const isIframe = !_.isNil(analyticsApiId)
  const isYardSummary = URLSubmenu === 'daily'

  useEffect(() => {
    const submenuDefaultOption = analyticsInitialMode[URLViewMode]

    if (_.isNil(URLSubmenu) && !_.isNil(submenuDefaultOption)) {
      history.replace(`/analytics/${URLViewMode}/${submenuDefaultOption}`)
    }
  }, [URLSubmenu, URLViewMode])

  useEffect(() => {
    setDashboardUrl(null)

    const getDashboardUrl = async () => {
      const { url } = await api.dashboard.get(analyticsApiId, yardName)
      setDashboardUrl(url)
    }

    if (isIframe) {
      getDashboardUrl()
    }
  }, [yardName, URLSubmenu, URLViewMode, isIframe, analyticsApiId])

  const date = {
    startDate: moment.tz(yardSummarySelectedDate, timezone),
    timezone,
    untilYesterday: true,
    onChange: ({ startDate }) => {
      setYardSummarySelectedDate(startDate.format('YYYY-MM-DD'))
    },
  }

  const headerProps = {
    title: 'Analytics',
    date: isYardSummary ? date : null,
    tabs: {
      items: viewModeOptions,
      activeItem: URLViewMode,
      onChange: (value) => history.push(`/analytics/${value}`),
    },
  }

  const subMenuProps = {
    items: submenuOptionsList[URLViewMode],
    activeItem: URLSubmenu,
    onChange: (value) => history.push(`/analytics/${URLViewMode}/${value}`),
  }

  function renderContent() {
    if (_.isNil(dashboardUrl) && isIframe) {
      return (
        <S.LoadingContainer>
          <Loading />
        </S.LoadingContainer>
      )
    }

    if (isIframe) {
      return <S.IFrame src={dashboardUrl} frameBorder="0" allowTransparency />
    }

    // At the request of the client this section of the Analytics page is removed.
    // We have kept the YarmSummary class at their request, as well as updated it to use modern functional components.
    // if (isYardSummary) {
    //   return (
    //     <S.YardSummaryContainer>
    //       <YardSummary date={yardSummarySelectedDate} />
    //     </S.YardSummaryContainer>
    //   )
    // }

    return null
  }

  return (
    <>
      <NewHeader {...headerProps} />
      <NewContainer>
        {!_.isNil(submenuOptionsList[URLViewMode]) ? (
          <NewTabs {...subMenuProps} />
        ) : null}

        {renderContent()}
      </NewContainer>
    </>
  )
}

Analytics.propTypes = {
  yardName: PropTypes.string.isRequired,
  URLViewMode: PropTypes.oneOf(['productivity', 'vehicles', 'safety'])
    .isRequired,
  URLSubmenu: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
}
