import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'

import { prop, isNil, filter, defaultTo } from 'ramda'
import {
  NewContainer,
  NewHeader,
  NewTabs,
  Na,
  NewToggle,
  NewToolTip,
  NewModal,
  AllowedYardsCell,
} from '../../components'

import ChangePassword from './ChangePassword'

import { history, getUserFullName } from '../../utils'
import { roleNameToId } from '../../config'
import { canChange } from '../../permissions/user'

import * as S from './styles'

import addIcon from '../../images/icons/icon_add_dark.svg'
import tooltipAddIcon from '../../images/tooltip/add.svg'
import workerIcon from '../../images/tooltip/workers.svg'
import keyIcon from '../../images/icons/icon_key_white.svg'
import trashIcon from '../../images/icons/icon_trash_white.svg'
import refreshIcon from '../../images/icons/icon_refresh.svg'

const activityPeriod = { months: 2 }

function isUserActive({ person, timezone }) {
  const isUserRecentlyCreated = moment
    .tz(person.userCreatedAt, timezone)
    .isSameOrAfter(moment.tz(timezone).subtract(activityPeriod))

  if (_.isNil(person.userLastLogin)) {
    return isUserRecentlyCreated
  }

  const hasUserRecentLogin = moment
    .tz(person.userLastLogin, timezone)
    .isSameOrAfter(moment.tz(timezone).subtract(activityPeriod))

  return hasUserRecentLogin || isUserRecentlyCreated
}

function renderTimestamp({ timestamp, timezone }) {
  const date = moment.tz(timestamp, timezone).format('MM/DD/YYYY')
  const time = moment.tz(timestamp, timezone).format('HH:mm')

  return (
    <>
      {date}
      <br />
      {time}
    </>
  )
}

const filterOptions = [
  { key: 0, value: 'all' },
  { key: 1, value: 'drivers' },
  { key: 2, value: 'leadership' },
]

const viewModeOptions = [
  { key: 0, value: 'info' },
  { key: 1, value: 'analytics (coming soon)', disabled: true },
]

const tableHeaders = [
  { key: 0, text: 'Last' },
  { key: 1, text: 'First' },
  { key: 2, text: 'Home Yard' },
  { key: 3, text: 'Other Yards' },
  { key: 4, text: 'Last Login' },
  { key: 5, text: 'Kronos ID' },
  { key: 6, text: 'Tos ID' },
  { key: 7, text: 'Last Audited' },
  { key: 8, text: 'Actions' },
]

const ManagePeople = ({
  loadUsers,
  setSortedHeader,
  setSortOrder,
  resetUserPassword,
  deleteUser,
  reactivateUser,
  selectedYard,
  locationQuery,
  tableSortHeader,
  tableSortOption,
  userList,
  timezone,
  loggedUserRole,
  loggedUserId,
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedTab, setSelectedTab] = useState('all')
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [showDeactivateUserModal, setShowDeactivateUserModal] = useState(false)
  const [showReactivateUserModal, setShowReactivateUserModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState()
  const [showInactivePeople, setShowInactivePeople] = useState(true)
  const [showDeactivatedPeople, setShowDeactivatedPeople] = useState(false)

  // TODO code cleanup
  // TODO double check only admins can see the deleted users or reactivation button
  useEffect(() => {
    loadUsers(
      selectedYard,
      loggedUserRole.toLowerCase() === 'admin' ||
        loggedUserRole.toLowerCase() === 'super_admin',
    )
  }, [loadUsers, selectedYard, loggedUserRole])

  const { value: initialList, isLoading: isLoadingUserList } = userList

  const viewMode = _.get(locationQuery, 'view_mode', 'info')

  function handleSearchQuery(value) {
    setSearchQuery(value)
  }

  function handleFilter(value) {
    setSelectedTab(value)
  }

  function handleSortedHeader(index) {
    setSortOrder(tableSortOption === 'desc' ? 'asc' : 'desc')
    setSortedHeader(index)
  }

  function handleOpenModal(type) {
    if (type === 'deactivate') return setShowDeactivateUserModal(true)
    if (type === 'password') return setShowChangePasswordModal(true)
    if (type === 'reactivate') return setShowReactivateUserModal(true)

    return null
  }

  function handleCloseModal() {
    setShowDeactivateUserModal(false)
    setShowChangePasswordModal(false)
    setShowReactivateUserModal(false)
  }

  function tableContentBySelectedTab() {
    if (selectedTab === 'drivers') {
      return filter((user) => user.role_id === roleNameToId.DRIVER)(initialList)
    }

    if (selectedTab === 'leadership') {
      return filter((user) => user.role_id !== roleNameToId.DRIVER)(initialList)
    }

    return initialList
  }

  function handleUserAction({ e, rowIndex, value, workerList }) {
    e.stopPropagation()

    const user = workerList[rowIndex]

    switch (value) {
      case 'EDIT':
        history.push(`/users/${user.id}`)
        break
      case 'VIEW':
        history.push(`/users/view/${user.id}`)
        break
      case 'CHANGE_PASSWORD':
        setSelectedUser(user)
        handleOpenModal('password')
        break
      case 'DEACTIVATE':
        setSelectedUser(user)
        handleOpenModal('deactivate')
        break
      case 'REACTIVATE':
        setSelectedUser(user)
        handleOpenModal('reactivate')
        break
      default:
        break
    }
  }

  function renderActionMenu({
    rowIndex,
    workerList,
    isUserEnableToEdit,
    isUserDeactivated,
    isUserEnableToReactivate = false,
  }) {
    const actions = [
      {
        value: 'CHANGE_PASSWORD',
        icon: keyIcon,
        isUserEnableToEdit,
        toolTip: {
          title: 'Update password',
          description: 'Click to change password for this user',
        },
      },
    ]

    if (isUserDeactivated) {
      actions.push({
        value: 'REACTIVATE',
        icon: refreshIcon,
        isUserEnableToEdit: isUserEnableToReactivate,
        toolTip: {
          title: 'Reactivate user',
          description: 'Click to reactivate this user',
        },
      })
    } else {
      actions.push({
        value: 'DEACTIVATE',
        icon: trashIcon,
        isUserEnableToEdit,
        toolTip: {
          title: 'Deactivate user',
          description: 'Click to remove this user',
        },
      })
    }

    return (
      <S.ActionContainer>
        {actions.map((userAction, index) => (
          <NewToolTip key={index} {...userAction.toolTip}>
            <S.Action
              icon={userAction.icon}
              disabled={
                (userAction.value !== 'REACTIVATE' &&
                  userAction.isUserEnableToEdit === false) ||
                (userAction.value === 'REACTIVATE' &&
                  userAction.isUserEnableToEdit === false)
              }
              onClick={(e) =>
                handleUserAction({
                  e,
                  rowIndex,
                  value: userAction.value,
                  workerList,
                })
              }
            >
              <S.Image src={userAction.icon} />
            </S.Action>
          </NewToolTip>
        ))}
      </S.ActionContainer>
    )
  }

  function tableContentBySearchResult(list) {
    const userWithMetricsList = list.map((user) => ({
      ...user,
    }))

    const query = searchQuery.toLowerCase().replace(/\s\s+/g, ' ')

    return userWithMetricsList.filter((user) => {
      const firstName = isNil(user.first_name) ? '' : user.first_name.toLowerCase()
      const lastName = isNil(user.last_name) ? '' : user.last_name.toLowerCase()
      const name = `${firstName} ${lastName}`
      return name.includes(query) || String(user.username).includes(query)
    })
  }

  function handleShowInactivePeopleChange() {
    setShowInactivePeople(!showInactivePeople)
  }

  function handleshowDeactivatedPeopleChange() {
    setShowInactivePeople(false)
    setShowDeactivatedPeople(!showDeactivatedPeople)
  }

  function getInactivePeoplePercentage({ allPeople, inactivePeople }) {
    const allPeopleSize = _.size(allPeople)
    const inactivePeopleSize = _.size(inactivePeople)

    return allPeopleSize > 0
      ? Math.round((100 * inactivePeopleSize) / allPeopleSize)
      : 0
  }

  function handleDeactivateUser() {
    deleteUser({ userId: _.get(selectedUser, 'id') })
  }

  // TODO this should probably reload the user list on the table. However,
  // the user list is not being updated after the user is deactivated, so we'll make this a future update.
  function handleReactivateUser() {
    reactivateUser({ userId: _.get(selectedUser, 'id') })
  }

  const listBySelectedTab = tableContentBySelectedTab()
  const filteredList = tableContentBySearchResult(listBySelectedTab)

  const titleAction = [
    {
      key: 0,
      icon: addIcon,
      tooltip: {
        titleIcon: tooltipAddIcon,
        title: 'Add in worker',
        action: 'Click to create a profile for a worker',
      },
      onClick: () => history.push('/users/create'),
    },
  ]

  const filterTabs = {
    items: filterOptions,
    activeItem: 'all',
    onChange: (value) => handleFilter(value),
    tooltip: {
      titleIcon: workerIcon,
      title: 'Sorting by worker type',
      action: 'Click on tabs to sort the workers',
      actionType: 'sort',
    },
  }

  const search = {
    handleSearchQuery,
    placeholder: 'Search',
  }

  const viewModeTabs = {
    items: viewModeOptions,
    activeItem: viewMode,
    onChange: (value) =>
      history.push({
        pathname: `/yard/${selectedYard}/users`,
        query: { view_mode: value },
      }),
  }

  // Why are we converting a perfectly fine user object to a table row
  // AND THEN FILTERING IT AGAIN? Why not just filter the user object?
  const allPeople = filteredList.map((user, index) => {
    const userLastLogin = _.get(user, 'last_login_time')
    const userCreatedAt = _.get(user, 'created_at')
    const userDeactivatedAt = _.get(user, 'deleted_at')

    const lastLoginTime = userLastLogin ? (
      renderTimestamp({ timestamp: userLastLogin, timezone })
    ) : (
      <Na text={'Never logged in'} />
    )
    const lastLoginOrderValue = isNil(userLastLogin)
      ? 0
      : new Date(userLastLogin).getTime()

    const workerRole = _.get(user, 'Role.name')
    const workerId = _.get(user, 'id')

    const workerLastName = _.get(user, 'last_name')
    const workerFirstName = _.get(user, 'first_name')

    const allowedYards = _.get(user, 'AllowedYards')
    const userYard = _.get(user, 'Yard')
    const { code: yardCode } = userYard
    const userUsername = prop('username')(user)
    const userTosId = prop('tos_id')(user)

    const isUserEnableToEdit = canChange({
      loggedUser: {
        id: loggedUserId,
        role_id: roleNameToId[loggedUserRole],
      },
      targetUser: {
        id: workerId,
        role_id: roleNameToId[workerRole],
      },
    })

    const isUserEnableToReactivate =
      loggedUserRole.toLowerCase() === 'admin' ||
      loggedUserRole.toLowerCase() === 'super_admin'

    const userDetails = isUserEnableToEdit
      ? `/users/${user.id}`
      : `/users/view/${user.id}`

    const lastAudit = _.get(user, 'last_audited_at')
    const lastAuditTimestamp = moment.tz(lastAudit, timezone)
    const lastAuditRender = lastAuditTimestamp.isValid() ? (
      renderTimestamp({ timestamp: lastAudit, timezone })
    ) : (
      <Na />
    )

    const defaultToNa = defaultTo(<Na />)

    return {
      columns: {
        0: {
          value: _.defaultTo(workerLastName, ''),
          render: _.isNil(workerLastName) ? (
            <Na />
          ) : (
            <S.Name>{workerLastName}</S.Name>
          ),
        },
        1: {
          value: _.defaultTo(workerFirstName, ''),
          render: _.isNil(workerFirstName) ? (
            <Na />
          ) : (
            <S.Name>{workerFirstName}</S.Name>
          ),
        },
        2: { value: yardCode },
        3: {
          value: allowedYards,
          render: <AllowedYardsCell allowedYards={allowedYards} />,
        },
        4: {
          value: lastLoginOrderValue,
          render: _.defaultTo(lastLoginTime, ''),
        },
        5: { value: userUsername, render: defaultToNa(userUsername) },
        6: { value: userTosId, render: defaultToNa(userTosId) },
        7: {
          value: lastAudit,
          render: lastAuditRender,
        },
        8: {
          value: '',
          render: renderActionMenu({
            rowIndex: index,
            workerList: filteredList,
            isUserEnableToEdit,
            isUserDeactivated: !isNil(userDeactivatedAt),
            isUserEnableToReactivate,
          }),
        },
      },
      onClick: () => history.push(userDetails),
      id: workerId,
      userLastLogin,
      userCreatedAt,
      userDeactivatedAt,
    }
  })

  const activePeople = allPeople.filter((person) =>
    isUserActive({ person, timezone }),
  )

  const inactivePeople = allPeople.filter(
    (person) => !isUserActive({ person, timezone }),
  )

  const deactivatedPeople = allPeople.filter((person) => {
    const { userDeactivatedAt } = person
    return !isNil(userDeactivatedAt)
  })

  let selectedPeopleRows

  if (showDeactivatedPeople) {
    selectedPeopleRows = deactivatedPeople
  } else {
    selectedPeopleRows = showInactivePeople
      ? _.difference(allPeople, deactivatedPeople)
      : activePeople
  }

  const sortedRows = _.orderBy(
    selectedPeopleRows,
    (row) =>
      tableSortHeader === 0
        ? row.columns[tableSortHeader].value.split(' ').reverse()
        : row.columns[tableSortHeader].value,
    [tableSortOption],
  )

  const selectedUserFullName = getUserFullName(selectedUser)

  const deactivateUserModalProps = {
    visible: showDeactivateUserModal,
    onConfirm: handleDeactivateUser,
    onClose: handleCloseModal,
    onCancel: handleCloseModal,
    title: 'Are you sure?',
    message: `Do you really want to deactivate user ${selectedUserFullName}?`,
    confirmText: 'Confirm',
    cancelText: 'Cancel',
    type: 'warning',
  }

  const changePasswordModalProps = {
    visible: showChangePasswordModal,
    onClose: handleCloseModal,
    title: 'Password Update',
    message: `For ${selectedUserFullName}`,
    confirmText: 'Confirm',
    cancelText: 'Cancel',
    type: 'warning',
    hideControls: true,
  }

  const reactivateUserModalProps = {
    visible: showReactivateUserModal,
    onConfirm: handleReactivateUser,
    onClose: handleCloseModal,
    onCancel: handleCloseModal,
    title: 'Reactivate User',
    message: `Do you really want to reactivate user ${selectedUserFullName}?`,
    confirmText: 'Confirm',
    cancelText: 'Cancel',
    type: 'warning',
  }

  return (
    <>
      <NewHeader
        title="People"
        titleAction={titleAction}
        tabs={filterTabs}
        search={search}
      />
      <NewContainer>
        <S.Row>
          <NewTabs {...viewModeTabs} />
          <NewToggle
            isChecked={showInactivePeople}
            onChange={handleShowInactivePeopleChange}
            text={`Toggle to ${
              showInactivePeople ? 'HIDE' : 'SHOW'
            } inactive people (${getInactivePeoplePercentage({
              allPeople,
              inactivePeople,
            })}%)`}
          />
          {(loggedUserRole.toLowerCase() === 'admin' ||
            loggedUserRole.toLowerCase() === 'super_admin') && (
            <NewToggle
              isChecked={showDeactivatedPeople}
              onChange={handleshowDeactivatedPeopleChange}
              text={`Toggle to ${
                showDeactivatedPeople ? 'HIDE' : 'SHOW'
              } deactivated users`}
            />
          )}
        </S.Row>
        <S.Gap />
        <S.StyledNewTable
          headers={tableHeaders}
          rows={sortedRows}
          searchString={searchQuery}
          sortedHeader={tableSortHeader}
          onSortedHeaderChange={handleSortedHeader}
          sortOrder={tableSortOption}
          isLoading={isLoadingUserList}
        />
      </NewContainer>

      <NewModal {...changePasswordModalProps}>
        {showChangePasswordModal && (
          <ChangePassword
            selectedUser={selectedUser}
            resetUserPassword={resetUserPassword}
            handleCloseModal={handleCloseModal}
          />
        )}
      </NewModal>

      <NewModal {...deactivateUserModalProps} />

      <NewModal {...reactivateUserModalProps} />
    </>
  )
}

ManagePeople.propTypes = {
  loadUsers: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  setSortedHeader: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  resetUserPassword: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  reactivateUser: PropTypes.func.isRequired,
  getDeactivatedUsers: PropTypes.func.isRequired,
  selectedYard: PropTypes.number.isRequired,
  tableSortHeader: PropTypes.number.isRequired,
  tableSortOption: PropTypes.oneOf(['desc', 'asc']).isRequired,
  timezone: PropTypes.string.isRequired,
  loggedUserRole: PropTypes.string.isRequired,
  loggedUserId: PropTypes.number.isRequired,
  locationQuery: PropTypes.shape({
    view_mode: PropTypes.string,
  }).isRequired,
  userList: PropTypes.shape({
    isLoading: PropTypes.bool,
    value: PropTypes.shape({
      role_id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      last_login_time: PropTypes.string,
      username: PropTypes.string,
      tos_id: PropTypes.string,
    }),
    order: PropTypes.shape({
      column: PropTypes.string,
      direction: PropTypes.oneOf(['desc', 'asc']),
    }),
  }).isRequired,
}

export default ManagePeople
