import Promise from 'bluebird'
import _ from 'lodash'
import { createTypes } from './utils'
import { getShortName } from '../utils'
import api from '../api'
import { showAlert } from './alertMessageActions'

export const manageUsersTypes = createTypes('manageUsers', [
  'REVOKE_TOKEN',
  'USER_LIST_ORDER_SET',
  'METRICS_INTERVAL_OPTION_SET',
  'METRICS_INTERVAL_OPTION_ROLLBACK',
  'SORT_HEADER_CHANGED',
  'LIST_ORDERING_CHANGED',
  { value: 'USERS', isAsync: true },
  { value: 'DEACTIVATE', isAsync: true },
  { value: 'REACTIVATE', isAsync: true },
  { value: 'LOAD_METRICS_BY_USER', isAsync: true },
])

export const loadUsers = (yardId, deactivated = false) => (dispatch) => {
  dispatch({ type: manageUsersTypes.USERS_REQUESTED })
  return api.users
    .getAll({ yardId, deactivated })
    .then((data) => {
      const users = data.users.map((user) => ({
        ...user,
        role_name: user.Role.name,
        short_name: getShortName({
          firstName: user.first_name,
          lastName: user.last_name,
        }),
      }))
      dispatch({
        type: manageUsersTypes.USERS_LOADED,
        payload: users,
      })
    })
    .catch(() => {
      dispatch({
        type: manageUsersTypes.USERS_FAILED,
      })
    })
}

export const resetUserPassword = (userId, newPassword) => (dispatch) =>
  api.auth
    .resetPassword({ userId, newPassword })
    .then(() => {
      dispatch(
        showAlert({
          type: 'success',
          message: 'Password successfully updated.',
        }),
      )
    })
    .tapCatch((error) => {
      const message = _.get(error, 'response.data.errors[0].message')

      dispatch(
        showAlert({
          type: 'failure',
          message: _.isNil(message) ? 'Failed to update password.' : message,
        }),
      )
    })

export const revokeToken = (userId) => (dispatch) => {
  dispatch({
    type: manageUsersTypes.REVOKE_TOKEN,
    payload: 'pending',
  })
  api.auth
    .revokeToken({ userId })
    .then(() => {
      dispatch(
        showAlert({
          type: 'success',
          message: 'Logged Worker out successfully.',
        }),
      )
      dispatch({
        type: manageUsersTypes.REVOKE_TOKEN,
        payload: 'success',
      })
    })
    .catch(() => {
      dispatch(
        showAlert({
          type: 'failure',
          message: 'Failed to log Worker out.',
        }),
      )
      dispatch({
        type: manageUsersTypes.REVOKE_TOKEN,
        payload: 'error',
      })
    })
}

export const deleteUser = ({ userId }) => (dispatch) => {
  dispatch({ type: manageUsersTypes.DEACTIVATE_REQUESTED })
  return Promise.resolve({ userId })
    .then(api.users.destroy)
    .tap(() => {
      dispatch({
        type: manageUsersTypes.DEACTIVATE_LOADED,
        payload: {
          userId,
        },
      })
      dispatch(
        showAlert({
          type: 'success',
          message: 'User successfully deactivated.',
        }),
      )
    })
    .tapCatch((error) => {
      const message = _.get(error, 'response.data.errors[0].message')

      dispatch({ type: manageUsersTypes.DEACTIVATE_FAILED })
      dispatch(
        showAlert({
          type: 'failure',
          message: message || 'Failed to deactivate the user.',
        }),
      )
    })
}

export const reactivateUser = ({ userId }) => (dispatch) => {
  dispatch({ type: manageUsersTypes.REACTIVATE_REQUESTED })
  return Promise.resolve({ userId })
    .then(api.users.reactivate)
    .tap(() => {
      dispatch({
        type: manageUsersTypes.REACTIVATE_LOADED,
        payload: {
          userId,
        },
      })
      dispatch(
        showAlert({
          type: 'success',
          message: 'User successfully reactivated.',
        }),
      )
    })
    .tapCatch((error) => {
      const message = _.get(error, 'response.data.errors[0].message')

      dispatch({ type: manageUsersTypes.REACTIVATE_FAILED })
      dispatch(
        showAlert({
          type: 'failure',
          message: message || 'Failed to reactivate the user.',
        }),
      )
    })
}

export const setUserListOrder = (order) => ({
  type: manageUsersTypes.USER_LIST_ORDER_SET,
  payload: {
    order,
  },
})

export const loadMetricsByUser = ({ yardId, startDate, endDate }) => (
  dispatch,
) => {
  dispatch({ type: manageUsersTypes.LOAD_METRICS_BY_USER_REQUESTED })
  return Promise.resolve({
    yardId,
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
  })
    .then(api.users.getMetrics)
    .tap(({ metrics }) => {
      dispatch({
        type: manageUsersTypes.LOAD_METRICS_BY_USER_LOADED,
        payload: metrics,
      })
    })
    .tapCatch(() => {
      dispatch({ type: manageUsersTypes.LOAD_METRICS_BY_USER_FAILED })
      dispatch({ type: manageUsersTypes.METRICS_INTERVAL_OPTION_ROLLBACK })
    })
}

export const setMetricsIntervalOption = (option) => ({
  type: manageUsersTypes.METRICS_INTERVAL_OPTION_SET,
  payload: option,
})

export const setSortedHeader = (index) => ({
  type: manageUsersTypes.SORT_HEADER_CHANGED,
  payload: index,
})

export const setSortOrder = (order) => ({
  type: manageUsersTypes.LIST_ORDERING_CHANGED,
  payload: order,
})
