import styled from 'styled-components/macro'

export const Content = styled.div`
  background: var(--lighter-neutral-99);
  margin: 0 auto;
  overflow-y: auto;
  padding: 10px;
  max-width: 100%;
`

export const Preview = styled.div`
  width: 100%;
  width: 205mm;
  height: 290mm;
`

export const Header = styled.div`
  text-align: center;
  color: var(--lighter-neutral-99);
  background-color: var(--darker-blue-20);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 15px;
  margin-bottom: 10px;
`

export const Icon = styled.img`
  height: 20px;
  object-fit: contain;
`

export const Text = styled.span`
  font-family: 'ArticulatCF-Medium';
  width: fit-content;

  ${(props) =>
    props.bold &&
    `
    font-family: 'ArticulatCF-Bold';
  `}

  ${(props) =>
    props.uppercase &&
    `
    text-transform: uppercase;
  `}

  ${(props) =>
    props.small &&
    `
    letter-spacing: 1px;
    font-size: 12px;
  `}
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  align-items: center;
  gap: 20px;

  ${(props) =>
    props.metrics &&
    `
    padding-bottom: 20px;
    border-bottom: 1px solid var(--base-neutral);
  `}
`

export const AuditDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  gap: 20px;

  .full-column {
    grid-column: span 3 / span 3;
    grid-column-start: 1;
    grid-row-start: 2;
  }
  .short-column {
    grid-row-start: 1;
  }

  ${(props) =>
    props.metrics &&
    `
    padding-bottom: 20px;
    border-bottom: 1px solid var(--base-neutral);
  `}
`

export const Box = styled.div`
  border: 1px solid var(--base-neutral);
  padding: 5px 10px;
  margin-top: 4px;
  border-radius: var(--border-radius);

  ${(props) =>
    props.highlight &&
    `
    background-color: var(--lighter-neutral-96);
  `}

  ${(props) =>
    props.bigFont &&
    `
    font-size: 30px;
  `}
`

export const CommentBox = styled.div`
  margin-bottom: 20px;
`

export const AuditContainer = styled.section`
  padding: 16px 0 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  page-break-inside: avoid;

  :not(:last-of-type) {
    border-bottom: 1px solid var(--base-neutral);
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
