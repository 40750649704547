import { connect } from 'react-redux'
import { path } from 'ramda'

import ManagePeople from './ManagePeople'

import {
  loadUsers,
  setSortedHeader,
  setSortOrder,
  resetUserPassword,
  deleteUser,
  reactivateUser,
} from '../../actions/manageUsersActions'

import { getYardTimeZone } from '../../selectors'

const mapStateToProps = (state, ownProps) => ({
  selectedYard: state.general.selectedYard,
  locationQuery: ownProps.location.query,
  tableSortOption: state.manageUsers.tableSortOption,
  tableSortHeader: state.manageUsers.tableSortHeader,
  userList: state.manageUsers.userList,
  timezone: getYardTimeZone(state),
  loggedUserRole: path(['Role', 'name'])(state.general.userData),
  loggedUserId: state.general.userData.id,
})

const mapDispatchToProps = {
  loadUsers,
  setSortedHeader,
  setSortOrder,
  resetUserPassword,
  deleteUser,
  reactivateUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePeople)
